import React from 'react'
import { Link, graphql } from 'gatsby'
import './post.css'
import './pageWrapper.css'
import './logos.scss'
import Layout from '../components/layout'

const IndexPage = props => {
  const postList = props.data.allMarkdownRemark
  return (
    <div className="site">
      <Layout>
        <div className="about-me">
          <p>
            I'm a frontend developer and team lead at IBM. I focus on making a
            platform for people to use IBM software. I also am an avid
            participant of Tech Twitter. You can find me at{' '}
            <a href="http://www.twitter.com/joehughesdev">@JoeHughesDev</a>
          </p>
        </div>
        <div
          style={{
            padding: '1.45rem',
          }}
        >
          <h1 className="Recent-Articles">Recent Articles</h1>
          {postList.edges.map(({ node }, i) => (
            <article key={i} className="article">
              <div className="post-list">
                <Link to={node.fields.slug} className="article-title">
                  <h3>{node.frontmatter.title}</h3>
                </Link>
                <p>{node.excerpt}</p>
                <Link to={node.fields.slug} className="read-more-link">
                  Read More...
                </Link>
              </div>
            </article>
          ))}
        </div>
      </Layout>
    </div>
  )
}

export default IndexPage

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(
      filter: { frontmatter: { date: { lte: "2020-08-09" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
          }
        }
      }
    }
  }
`
